.App {
  text-align: center;
}

.App-logo {
  height: 13vmin;
  pointer-events: none;
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
  padding: 25px 0px;
  border-bottom: 1px solid rgb(255, 255, 255);
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}


.App-header {
  background-image: linear-gradient(#113D5C, #191a1d);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
